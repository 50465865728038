import App from './App.vue'
import axios from 'axios'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import keycloak from 'keycloak-js'
import moment from 'moment'
import routes from 'vue-auto-routing'
import vuecomponents from '@118group/vuecomponents'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      children: routes
    }
  ]
});

router.afterEach((to, from) => {
  if (to.matched.length == 0) {
    document.title = require('../package.json').friendlyName + " - Not Found";
    return;
  }

  var pageName = to.matched.filter(m => m.components)[0].components.default.friendlyName;
  document.title = require('../package.json').friendlyName + (pageName ? " - " : "") + (pageName ? pageName : "");  
});

const app = createApp(App)

app.directive('focus', {
  mounted: (el) => el.focus()
});

app.directive('focus-if', {
  mounted: (el, binding) => {
      if (binding.value) {
          el.focus();
      }
  }
});

app.config.globalProperties.$http = axios;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$guid = function () {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
app.config.globalProperties.$keycloak = new keycloak({
  url: 'https://auth.118group.co.uk/', realm: 'master', clientId: require('../package.json').name, onLoad: 'login-required'
});

app
  .use(router)
  .use(vuecomponents)
  .mount('#app');
